import { TAddon, TDuration, TPlanSummary } from 'common/cms-types'

export const getPriceIds = (
  plans: TPlanSummary[],
  addons: TAddon[] | null,
  currentPlan: string | null,
  currentAddons: string[],
  duration: TDuration,
) => {
  if (!currentPlan) {
    return { addons: [], product_id: '' }
  }
  const selectedPlan = plans.find((v) => v.slug === currentPlan)
  const selectedAddons = addons?.filter((v) => currentAddons.includes(v.slug))
  if (selectedPlan) {
    return {
      addons: selectedAddons?.map((v) =>
        duration === 'month'
          ? v.pricing.month_price_id
          : v.pricing.year_price_id,
      ),
      product_id:
        duration === 'month'
          ? selectedPlan.pricing.month_price_id
          : selectedPlan.pricing.year_price_id,
    }
  } else {
    console.error('Could not find selected plan', currentPlan, plans)
  }
}
