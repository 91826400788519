import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  cashback: '£75 cashback',
  collect: 'Collect',
  collectDescription:
    'When we receive your post, we email you and keep it secure for 30 days until you can collect it.',
  collectSummary: 'Mail Handling\nDrop off/Pick up point',
  directorsServiceAddress: 'Full Privacy Service',
  directorsServiceAddressDescription:
    'Protect the privacy of your home <br class="d-md-none"/> address by using <br class="d-md-block d-none"/> our London <br class="d-md-none"/>address as <span class="d-sm-inline d-none">the</span> directors service address.',
  directorsServiceAddressModalContent:
    '<p class="lead text-semibold d-lg-block d-none">What is a service address?</p> <p class="text-medium text-medium--lt1 pe-xxl-10">This service is commonly purchased together with a Registered Office Service for a Limited Company as Companies House legally require all directors of UK companies and partners of LLP’s to register an address for the director on the public record. This address can be viewed <span class="d-xxxl-block">by the public on the Companies House website.</span></p>',
  directorsServiceAddressModalSubtitle:
    '<h5 class="text-semibold">Protect the privacy of your home address by using our London address as the directors service address.</h5>',
  forward: 'Forward',
  forwardDescription:
    'We forward all your post to your private address within one business day of receiving it.',
  forwardSummary: 'Mail Handling\nand Forwarding',
  freeConsultation: 'FREE Consultation',
  freePostageCredit: 'Free postage credit',
  freeQuote: 'FREE QUOTE',
  introductionToAnAccountant: 'Introduction to an Accountant',
  introductionToAnAccountantDescription:
    'Accountancy servicing for startups, freelancers <br/> and small businesses.',
  meetings: 'Meetings',
  meetingsDescription:
    '<span class="d-sm-inline d-block">Ideal for events, meetings</span> & training sessions.',
  meetingsSummary: 'Meeting Room Rental',
  noHandlingFees: 'No handling fees',
  noPostageFees: 'Free postage credit',
  officialGovernment: 'Official Government Mail Plan',
  phone: 'Phone',
  phoneDescription:
    '<span class="d-sm-inline d-block">Transfer to UK/EU/US Land-line,</span> as well as UK Mobiles.',
  phoneSummary: 'London 020 Number',
  primeLondonNumber: 'Prime London number',
  getYourFreeQuote: 'Get your FREE quote',
  scanLite: 'Scan Lite',
  claimOffer: 'Claim offer',
  scanLiteDescription:
    '<span class="d-sm-inline d-block">We scan and email all your post to</span> you within one business day of receiving it.',
  scanLiteSummary: 'Mail Handling\nScanned and Emailed',
  scanPro: 'Scan Pro',
  scanProDescription:
    'We scan, email, and forward the hard copies to you by post within one business day.',
  scanProSummary: 'Mail Handling\nScan, Email & Post',
  superscriptBusinessInsurance: 'Superscript Business Insurance',
  superscriptBusinessInsuranceDescription:
    'Save <strong>15%</strong> on customisable insurance for businesses big, \n' +
    'little and bold.',
  superscriptBusinessInsuranceModalContent: `<p>We're chuffed to bits to partner with Superscript offering you customisable business insurance with 15% off*.</p>

<p>Insurance should be a smooth ride, not a hop, skip and a jump. Superscript customisable cover comes with UK-based support, monthly payment options and no fees for tweaking your policy. So whether you're an accountant or an architect, a tech company or a t-shirt designer, a crafter or a crypto startup, you can get business insurance that works for you.</p>

<ul>
  <li>Pick the covers you need, leave the ones you don't</li>
  <li>Pay monthly or annually, it's your choice</li>
  <li>Hassle-free claims and UK-based customer support</li>
  <li>Rated 4.8/5 stars on Trustpilot</li>
</ul>`,
  superscriptBusinessInsuranceModalTitle:
    'Save 15% on customisable insurance for businesses big, little and bold.\n',
  tideBusinessBanking: 'Tide Business Banking',
  tideBusinessBankingDescription:
    'Get a free bank account with <strong>£75 cashback</strong> <br/> and one year of <strong>free UK transfers</strong>.',
  tideBusinessBankingModalContent: `<p class="mb-lg-5 mb-3 pe-lg-4"><a class="color-pink4 text-decoration-none" href="/free-business-banking">Tide</a> offers powerful and simple free business bank accounts that can be opened in minutes, managed on your mobile and are FSCS-protected. With no credit checks required, and no monthly or annual fees, why not give <a class="color-pink4 text-decoration-none" href="/free-business-banking">Tide</a> a try alongside your existing account? Spend less time on admin and more time running your business.</p>
 <p class="pe-lg-5">Hoxton Mix members are eligible for an exclusive offer from Tide - <strong class="color-midnight2">£75 cashback</strong> and one year of <strong class="color-midnight2">free</strong> UK transfers!* Open an account using the code <strong class="color-midnight2">HOXTON</strong> to claim this offer.</p>`,
  tideBusinessBankingModalTitle:
    'Trusted by over 400,000 UK businesses and one of the UK’s fastest-growing digital business banking platforms',
  virtualOfficePhone: 'Prime London 020 number',
  virtualOfficePhoneDescription:
    'Get a virtual landline and divert <br class="d-md-none"/> your business calls <br class="d-md-block d-none"/> to your mobile <br class="d-md-none"/> and stay connected wherever you are.',
  virtualOfficePhoneModalContent:
    '<p class="text-medium text-medium--lt1 pe-xxl-5">At Hoxton Mix, you can create an established business image with our virtual phone service. Divert gives you prime London numbers that are managed and forwarded by you. Wherever you are, whether <span class="d-xxxl-block">you’re local or travelling internationally, you can manage your 020 number on the go.</span></p>',
  virtualOfficePhoneModalSubtitle:
    '<h4 class="text-semibold mb-4 pe-xxl-5">A virtual phone number that travels with you.</h4><h5 class="text-semibold mb-3 pe-xxl-5 d-lg-block d-none">Give your company a London presence with a 020 London virtual telephone number.</h5>',
  workFromAnywhere: 'Work from anywhere',
}
export const planSummaryStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
