import { Project } from 'common/project'
import { ApiTypes } from 'common/types/api-types'
import storage from './async-storage-api'
import { Router } from 'next/router'
import { makeStrings, setLanguage } from './localisation'
import { GAEvent, GAEventData, GAEventType } from '../types/global'

type WebAPI = ApiTypes & {
  getStoredLocale: (requestedLocale?: string) => string
  setStoredLocale: (requestedLocale: string) => void
}
function gtag(eventType: GAEventType, event?: GAEvent, data?: GAEventData) {
  if (eventType === 'page_view') {
    const payload = {
      event: 'Pageview',
      pagePath: document.location.href,
      pageTitle: document.title,
      visitorType: 'customer',
    }
    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  } else {
    const payload = {
      ecommerce_ga4: data,
      event,
    }

    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  }
}

// @ts-ignore
const API: WebAPI = {
  getStoredLocale: (requestedLocale?: string) => {
    return API.storage.getItemSync!('NEXT_LOCALE') || requestedLocale || 'en'
  },
  identify(id, otherProps) {
    API.log('ANALYTICS', 'Identify', id, otherProps)
  },

  isMobile: () => false,

  // @ts-ignore
  log(namespace: keyof (typeof Project)['logs'], ...args: any[]) {
    if (Project.logs[namespace]) {
      // eslint-disable-next-line no-console
      console.log.apply(this, [namespace, ...args])
    }
  },

  makeStrings,

  setStoredLocale: (locale: string) => {
    setLanguage(locale)
    API.storage.setItemSync!('NEXT_LOCALE', locale)
    // document.location = document.location
    // @ts-ignore
    const { asPath, pathname, query } = Router.router?.state || {}
    // change just the locale and maintain all other route information including href's query
    // @ts-ignore
    Router.router?.push({ pathname, query }, asPath.split('#')[0], { locale })
  },
  // @ts-ignore
  storage,

  trackEvent(event: GAEvent, data: GAEventData) {
    if (typeof dataLayer !== 'undefined') {
      gtag('event', event, data)
    }
  },

  trackPage() {
    if (typeof dataLayer !== 'undefined') {
      gtag('page_view')
    }
  },
}

export { API }
