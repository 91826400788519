import { TAddon, TDuration, TPlanSummary } from 'common/cms-types'
import { planSummaryStrings } from 'common/strings/plan-summary-strings'
import { GA4AddToCart, GA4Purchase } from '../../types/global'
import { TOrder } from 'common/types/order-type'

export const planToEvent = (data: TPlanSummary, duration: TDuration) => {
  return {
    currency: 'GBP',
    items: [
      {
        item_id:
          duration === 'month'
            ? data.pricing.month_price_id
            : data.pricing.year_price_id,
        item_name: `${planSummaryStrings[data.shortName]} ${duration}`,
        price: (
          (duration === 'month'
            ? data.pricing.month_price
            : data.pricing.year_price) / 100
        ).toFixed(2),
      },
    ],
    value: (
      (duration === 'month'
        ? data.pricing.month_price
        : data.pricing.year_price) / 100
    ).toFixed(2),
  } as GA4AddToCart
}

export const orderToEvent = (
  data: TPlanSummary,
  addons: TAddon[],
  duration: TDuration,
  order: TOrder,
) => {
  return {
    currency: 'GBP',
    items: [
      {
        item_id:
          duration === 'month'
            ? data.pricing.month_price_id
            : data.pricing.year_price_id,
        item_name: `${planSummaryStrings[data.shortName]} ${duration}`,
        price: (
          (duration === 'month'
            ? data.pricing.month_price
            : data.pricing.year_price) / 100
        ).toFixed(2),
        quantity: 1,
      },
    ].concat(
      addons.map((addon) => ({
        item_id:
          duration === 'month'
            ? addon.pricing.month_price_id
            : addon.pricing.year_price_id,
        item_name: `${planSummaryStrings[addon.title]} ${duration}`,
        price: (
          (duration === 'month'
            ? addon.pricing.month_price
            : addon.pricing.year_price) / 100
        ).toFixed(2),
        quantity: 1,
      })),
    ),
    tax: (order.tax / 100).toFixed(2),
    transaction_id: `${order.order_id}`,
    value: (order.sub_total / 100).toFixed(2),
  } as GA4Purchase
}
