import { FC, useEffect } from 'react'
import { useRouter } from 'next/router' // we need this to make JSX compile

type ScrollOnRouteType = {}
let firstTime = true
const handleRouteChange = () => {
  if (firstTime) {
    firstTime = false
    return
  }
  window.scrollTo({
    // @ts-ignore
    behavior: 'instant',
    left: 0,
    top: 0,
  })
}
const ScrollOnRoute: FC<ScrollOnRouteType> = ({}) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return <></>
}

export default ScrollOnRoute
