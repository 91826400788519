import { OpenGraphMedia } from 'next-seo/lib/types'
import { RTImageNode } from '@prismicio/types'
import { NextRouter } from 'next/router'
import { ReviewsAPIResponse, TPlanSummary, TReviews } from 'common/cms-types'
import { Offer, OfferSummary } from 'common/types/responses'
import { Crisp } from 'crisp-sdk-web'
export const omit = (obj: any, keys: string[]) => {
  const output = []
  for (const [key, value] of Object.entries(obj)) {
    if (!keys.includes(key)) {
      output.push([key, value])
    }
  }
  return Object.fromEntries(output)
}
export const Utils = {
  fromParam(str?: string): Record<string, any> {
    // {min:100,max:200} <- ?min=100&max=200
    const documentSearch =
      typeof document === 'undefined' ? '' : document.location.search

    if (!str && !documentSearch) {
      return {}
    }
    // eslint-disable-next-line
    const urlString = (str || documentSearch).replace(/(^\?)/, '');
    return JSON.parse(
      `{"${urlString.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => (key === '' ? value : decodeURIComponent(value)),
    )
  },
  getClientContext: (router: NextRouter) => {
    //todo : move to /project
    return {
      defaultLocale: router.defaultLocale,
      locale: router.locale,
      locales: router.locales,
    }
  },
  getLocaleID: (locale: string) => {
    let matchingLocale = locale
    if (!locale.includes('-')) {
      switch (locale) {
        case 'en': {
          matchingLocale = 'gb'
          break
        }
        default: {
        }
      }
      return `${locale}-${matchingLocale}`
    }
    return locale
  },
  getMonthsFree: (plan: TPlanSummary) => {
    const yearlyMonthCost = plan.pricing.month_price * 12
    return (
      (yearlyMonthCost - plan.pricing.year_price) / plan.pricing.month_price
    )
  },
  getOfferUrl(offer: Offer | OfferSummary) {
    return `/marketplace/${offer.slug}`
  },

  openChat() {
    Crisp.chat.open()
  },
  parseReviews(reviews: ReviewsAPIResponse['reviews']) {
    return reviews?.map((review) => {
      return {
        content:
          review.comments ||
          `The review received a rating of ${review.rating} stars, but there were no accompanying comments or feedback.`,
        date: review.date_created.split(' ')[0],
        name: review.author.name,
        rating: review.rating,
      }
    }) as TReviews['reviews']
  },
  prismicImageToOpenGraph: (prismicImage: RTImageNode) => {
    const res: OpenGraphMedia = {
      alt: prismicImage.alt || '',
      height: prismicImage.dimensions.height,
      url: prismicImage.url.replace('.webp', ''),
      width: prismicImage.dimensions.width,
    }
    return res
  },
  setProperties(data: Record<any, any>) {
    if (data) {
      if (data.user_id) {
        Crisp.user.setEmail(data.user_id)
      }
      Crisp.session.setData(data)
    }
  },
  toParam(obj?: Record<string, any>): string {
    // {min:100,max:200} -> min=100&max=200
    if (!obj) return ''
    const allDefined = omit(
      obj,
      Object.keys(obj).filter(
        (key) => typeof obj[key] === 'undefined' || obj[key] === null,
      ),
    )
    return Object.keys(allDefined)
      .map((k) => {
        // @ts-ignore
        let val = allDefined[k]
        if (Array.isArray('array')) {
          val = val.join(',')
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`
      })
      .join('&')
  },
}
