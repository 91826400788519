// import SecuredStorage from 'react-native-secured-storage';
import cookie from 'js-cookie'
import { NextPageContext } from 'next'
import { Project } from 'common/project'

const StorageManager = class {
  clear = async () => {
    console.error('Web does not support clear cookies')
    return Promise.resolve(false)
  }
  getItem = function (key: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return Promise.resolve(cookie.get(key))
  }
  setItem = function (key: string, value: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return Promise.resolve(cookie.set(key, value, { path: '/' }))
  }
  removeItem = function (key: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return Promise.resolve(cookie.remove(key))
  }
  getItemSync = function (key: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return cookie.get(key)
  }
  setItemSync = function (key: string, value: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return cookie.set(key, value, { path: '/' })
  }
  removeItemSync = function (key: string, ctx?: NextPageContext) {
    if (Project.logs.STORAGE) {
      console.log('STORAGE', 'GET', key, ctx)
    }
    return cookie.remove(key)
  }
}
export default new StorageManager()
