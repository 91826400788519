import { Utils } from 'common/utils'
import { useRouter } from 'next/router'
import { API } from './api'
export function readCookieData() {
  if (typeof document !== 'undefined') {
    try {
      const { c } = Utils.fromParam()
      if (c) {
        const data = JSON.parse(atob(c))
        Object.keys(data).map((key: string) => {
          API.storage.setItemSync(key, data[key])
        })
      }
    } catch (e) {}
  }
}
export default function () {
  const router = useRouter()
  if (typeof document !== 'undefined') {
    const { c, ...otherParams } = Utils.fromParam()
    if (c) {
      router.replace({
        pathname: document.location.pathname,
        query: otherParams,
      })
    }
  }
}
