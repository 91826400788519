import CryptoJS from 'crypto-js'
import { Project } from 'common/project'

const KEY = '686f78746f6e6d69782d696d6167652d70726f7879'
const SALT = '32587a4879754a347a4c406662506f714624334a'
const urlSafeBase64 = (string: string) => {
  return btoa(string).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const hexDecode = (hex: string) => {
  const res = CryptoJS.enc.Hex.parse(hex)
  return res
}

const sign = (salt: string, target: string, secret: string) => {
  const hmac = CryptoJS.algo.HMAC.create(
    CryptoJS.algo.SHA256,
    hexDecode(secret),
  )
  hmac.update(hexDecode(salt))
  hmac.update(target)
  return CryptoJS.enc.Base64.stringify(hmac.finalize())
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

export default function imageResize(
  _url: string | { default: { src: string } },
  width: number | null = null,
  height: number | null = null,
  _format = 'webp',
  debug = false,
) {
  let url = _url?.src || _url?.default?.src || _url
  if (!url) return url
  if (
    !url.startsWith('/') &&
    !url.startsWith('http') &&
    !url.startsWith('www')
  ) {
    url = `/${url}`
  }
  // strip storybook url
  if (!debug && url.startsWith('/static/media')) {
    url = url.replace('/static/media/public/', '/')
  }
  if (!Project.imageProxy || url.includes(Project.imageProxy)) {
    return url
  }
  const format = url.endsWith('.svg') ? 'svg' : _format
  if (debug) {
    return url
  }
  if (
    typeof window !== 'undefined' &&
    !url.startsWith('http') &&
    !url.startsWith('www')
  ) {
    const prefix = document.location.origin.includes('localhost')
      ? 'https://hoxtonmix.com'
      : document.location.origin
    url = prefix + url
  } else if (typeof window === 'undefined') {
    url = `${process.env.NEXT_PUBLIC_SITE_URL || 'https://hoxtonmix.com'}${url}`
  }
  const encoded_url = urlSafeBase64(url)
  const ratio = typeof window === 'undefined' ? 1 : window.devicePixelRatio || 1
  const processingOptions = `${
    width ? `/size:${parseInt(`${width}`)}:${parseInt(`${height}`)}` : ''
  }/resizing_type:fill/dpr:${ratio}/format:${format}/`
  const path = `${processingOptions}${encoded_url}.${format}`
  const signature = sign(SALT, path, KEY)
  const res = `${Project.imageProxy}${signature}${path}`

  return res
}
