import { FC } from 'react'
import useWindowSize from './util/useWindowSize'
import imageResize from 'project/image-proxy-web'

export type RatingsType = {}

export const Ratings: FC<RatingsType> = () => {
  const size = useWindowSize()
  return (
    <>
      {size.isLgOrHigher ? (
        <ul className='list-unstyled d-lg-flex d-none mt-5 rating-logos'>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={imageResize(
                require('public/images/ratings/cyber-essentials.png'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={imageResize(require('public/images/ratings/lw-logo.svg'))}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='Google Rating Icon'
              width={84}
              height={55}
              src={imageResize(
                require('public/images/ratings/homepage-google-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='ICO Rating Icon'
              width={94}
              height={55}
              src={imageResize(
                require('public/images/ratings/homepage-ico-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='SSL Icon'
              width={101}
              height={62}
              src={imageResize(
                require('public/images/ratings/homepage-cloudflare-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid fit-content'
              alt='Trusted Reviews Icon'
              width={182}
              height={56}
              src={imageResize(
                require('public/images/ratings/trusted_reviews_icon.svg'),
              )}
            />
          </li>
        </ul>
      ) : (
        <ul className='list-unstyled d-flex d-lg-none mt-5 mb-4 rating-logos mx-n3'>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={imageResize(
                require('public/images/ratings/cyber-essentials.png'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='Google Rating Icon'
              width={84}
              height={55}
              src={imageResize(
                require('public/images/ratings/homepage-google-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={imageResize(require('public/images/ratings/lw-logo.svg'))}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='ICO Rating Icon'
              width={95}
              height={56}
              src={imageResize(
                require('public/images/ratings/homepage-ico-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='SSL Icon'
              width={101}
              height={62}
              src={imageResize(
                require('public/images/ratings/homepage-cloudflare-footer-icon-desktop.svg'),
              )}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid fit-content'
              alt='Trusted Reviews Icon'
              width={146}
              height={31}
              src={imageResize(
                require('public/images/ratings/trusted_reviews_icon.svg'),
              )}
            />
          </li>
        </ul>
      )}
    </>
  )
}

Ratings.displayName = 'Ratings'
export default Ratings
