import Link from 'next/link'
import { useRouter } from 'next/router'
import { Children, cloneElement } from 'react'
import { ReactFC } from 'common/types/ReactFC'

interface ActiveLinkType {
  href?: string
  match?: any
  'aria-label'?: string
  scroll?: boolean
  className?: string
  as?: string
  prefetch?: boolean
  activeClassName?: string
}

const ActiveLink: ReactFC<ActiveLinkType> = ({
  href = '',
  match,
  as = href,
  scroll,
  prefetch,
  activeClassName = 'active',
  className: _className,
  ['aria-label']: ariaLabel,
  children,
}) => {
  const router = useRouter()
  const child = Children.only(children)
  // @ts-ignore
  let className = child.props.className || ''

  const currentPath = router.asPath.replace(/\//g, '')
  const compareHref = href.replace(/\//g, '')
  if (
    (!match && currentPath === compareHref && activeClassName) ||
    (match && (currentPath || router.pathname).match(match))
  ) {
    className = `${className} ${activeClassName}`.trim()
  }

  return (
    <Link
      className={_className}
      prefetch={prefetch}
      aria-label={ariaLabel}
      as={as}
      scroll={scroll}
      href={href}
    >
      {/*@ts-ignore*/}
      {cloneElement(child, { className })}
    </Link>
  )
}

export default ActiveLink
