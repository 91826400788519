import classNames from 'classnames'
import { ReactFC } from 'common/types/ReactFC'
import { ColorType } from 'common/types/color-type' // we need this to make JSX compile

// const paddingMap: Record<keyof CardType['padding'], string> = {
//   lg: 'px-5 py-2',
//   sm: 'px-5 py-2',
// }

export type CardType = {
  theme?: ColorType
  padding?: 'lg' | '0' | 'sm' | 'md' | 'xl'
  radius?: 'md' | 'lg'
  border?: boolean
  symbol?: boolean
  className?: string
  shadow?: boolean
  roundedBox?: boolean
  onClick?: () => void
}

const Card: ReactFC<CardType> = ({
  border,
  children,
  className,
  onClick,
  padding = '',
  radius,
  roundedBox = true,
  shadow,
  symbol,
  theme = 'grey4',
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        'card d-flex flex-column',
        `bg-${theme}`,
        shadow && 'card--shadow',
        padding && `card--padding-${padding}`,
        radius && `card--radius-${radius}`,
        {
          border: border,
          'card__bg-hm-symbol': symbol,
          'rounded-box': roundedBox,
        },
      )}
    >
      {children}
    </div>
  )
}

export default Card
