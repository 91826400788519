import Localized, { GlobalStrings, LocalizedStrings } from 'react-localization'
import { setStrings, stringRecords } from 'common/strings'

const allStrings: LocalizedStrings<any>[] = []
export const makeStrings = <T extends GlobalStrings<any>>(strings: T) => {
  const newStrings = new Localized(strings) as LocalizedStrings<
    (typeof strings)['en']
  >
  allStrings.push(newStrings)
  return newStrings
}

const Strings = makeStrings(stringRecords) as LocalizedStrings<
  (typeof stringRecords)['en']
>
export const setLanguage = function (language: string) {
  Strings.setLanguage(language)

  allStrings.map((v) => v.setLanguage(language))
}
// make strings available to common folder
setStrings(Strings)
export default Strings
