import Footer from 'components/nav/Footer'
import Header from 'components/nav/Header'
import { ReactFC } from 'common/types/ReactFC'
import { useWithoutNav } from 'project/isWithoutNav'

export type PageContainerProps = {
  hideFooterCard?: boolean
}
export const PageContainer: ReactFC<PageContainerProps> = ({
  children,
  hideFooterCard,
}) => {
  const hideNav = useWithoutNav()
  return (
    <>
      {!hideNav && <Header />}
      {children}
      {!hideNav && <Footer hideFooterCard={hideFooterCard} />}
    </>
  )
}

PageContainer.displayName = 'PageContainer'
export default PageContainer
