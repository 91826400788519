import { useEffect, useState } from 'react'

interface Size {
  isSmOrLower: boolean
  isMdOrLower: boolean
  isLgOrHigher: boolean
  isXlOrHigher: boolean
  isXxlOrHigher: boolean
  isXxxlOrHigher: boolean
}

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    isLgOrHigher: false,
    isMdOrLower: false,
    isSmOrLower: false,
    isXlOrHigher: false,
    isXxlOrHigher: false,
    isXxxlOrHigher: false,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        isLgOrHigher: window.innerWidth >= 992,
        isMdOrLower: window.innerWidth < 768,
        isSmOrLower: window.innerWidth <= 576,
        isXlOrHigher: window.innerWidth >= 1200,
        isXxlOrHigher: window.innerWidth >= 1480,
        isXxxlOrHigher: window.innerWidth >= 1680,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
