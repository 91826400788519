import React, { FC, ReactNode, useEffect, useState } from 'react' // we need this to make JSX compile
import Icon from './Icon'
import Button from './forms/Button'
import { ColorType } from 'common/types/color-type'
import useWindowSize from './util/useWindowSize'
import Strings from 'project/localisation'
import classnames from 'classnames'

export type AccordionItemType = {
  item: {
    title: string
    body: ReactNode
    titleAppend?: string
  }
  titleForItem?: string
  appendColor?: ColorType
  onClick: () => void
  active: boolean
  chevronColour?: string
  bodySize?: string
}

export type AccordionType = {
  chevronColour?: string
  appendColor?: ColorType
  items: AccordionItemType['item'][]
  expandAllButton?: boolean
  titleForItems?: string
  expandAllButtonMobileOnly?: boolean
  expandAllButtonDesktopOnly?: boolean
  activeItem?: number | null
  bodySize?: string
}
let useCollapse = () => ({
  getCollapseProps: (x?: any) => ({}),
  getToggleProps: (x?: any) => ({}),
})
const prom = import('react-collapsed')
let resolved = false
const AccordionItem: FC<AccordionItemType> = ({
  active,
  appendColor,
  bodySize,
  chevronColour,
  item,
  onClick,
  titleForItem,
}) => {
  const [ready, setReady] = useState(resolved)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (!resolved) {
      prom.then((res) => {
        resolved = true
        useCollapse = res.default
        setReady(true)
      })
    } else {
      setReady(true)
    }
  }, [])
  // @ts-ignore
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse()
  useEffect(() => {
    if (ready && isExpanded !== active) {
      setExpanded(active)
    }
  }, [active, isExpanded, ready, setExpanded])

  return (
    <>
      <div className='collapsible'>
        <div
          className='collapsible__header d-flex'
          {...getToggleProps({ onClick })}
        >
          <h4 className='lead-large-desktop text-semibold d-inline pe-sm-0 pe-sm-5 pe-5'>
            {item.title}{' '}
            {item.titleAppend && (
              <span className={`color-${appendColor}`}>{item.titleAppend}</span>
            )}
          </h4>
          <span className='ms-auto d-inline'>
            {isExpanded ? (
              <Icon
                fill={chevronColour}
                name='chevron-up'
                width={windowSize.isMdOrLower ? 15 : 20}
                height={windowSize.isMdOrLower ? 9 : 14}
              />
            ) : (
              <Icon
                fill={chevronColour}
                name='chevron-down'
                width={windowSize.isMdOrLower ? 15 : 20}
                height={windowSize.isMdOrLower ? 9 : 14}
              />
            )}
          </span>
        </div>
        <div {...getCollapseProps()}>
          {ready && (
            <div className={classnames('accordion-items-container', bodySize)}>
              {titleForItem && (
                <p className={`text-semibold color-${appendColor}`}>
                  {titleForItem}
                </p>
              )}
              {item.body}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const Accordion: FC<AccordionType> = ({
  activeItem = null,
  appendColor,
  bodySize = 'lead',
  chevronColour,
  expandAllButton,
  expandAllButtonDesktopOnly = false,
  expandAllButtonMobileOnly = false,
  items,
  titleForItems,
}) => {
  const [active, setActive] = useState<number | null>(activeItem)
  const windowSize = useWindowSize()

  return (
    <>
      {items.map((item, i) => {
        return (
          <AccordionItem
            chevronColour={chevronColour}
            key={i}
            appendColor={appendColor}
            titleForItem={titleForItems}
            item={item}
            onClick={() => {
              setActive(i === active ? null : i)
            }}
            active={active === i}
            bodySize={bodySize}
          />
        )
      })}
      {expandAllButton &&
        expandAllButtonMobileOnly &&
        windowSize.isSmOrLower && (
          <Button
            size={windowSize.isSmOrLower ? 'small' : 'default'}
            iconRight='chevron-down'
            iconRightColour='#0071E3'
            theme='text'
            className={classnames('d-block mx-auto mt-lg-5 mt-3', {
              'text-xl': !windowSize.isSmOrLower,
            })}
            iconWidth={12}
            iconHeight={7}
          >
            {Strings.expandAll}
          </Button>
        )}
      {expandAllButton &&
        expandAllButtonDesktopOnly &&
        !windowSize.isSmOrLower && (
          <Button
            size={windowSize.isSmOrLower ? 'small' : 'default'}
            iconRight='chevron-down'
            iconRightColour='#0071E3'
            theme='text'
            className={classnames('d-block mx-auto mt-lg-5 mt-3', {
              'text-xl': !windowSize.isSmOrLower,
            })}
            iconWidth={12}
            iconHeight={7}
          >
            {Strings.expandAll}
          </Button>
        )}
    </>
  )
}

export default Accordion
