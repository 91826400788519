import React, { FC } from 'react'
import Strings from 'project/localisation'
import { Constants } from 'common/utils'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type CopyrightType = {
  isLight?: boolean
}

const Copyright: FC<CopyrightType> = ({ isLight }) => {
  const { company_number, ico_number, mlr_number } =
    Constants.companyInformation
  return (
    <ul className='list-unstyled d-flex footer__copyright'>
      <li className='d-md-inline d-block copyright-text'>
        <div className='flex-row align-items-center justify-content-center'>
          {isLight ? (
            <img
              loading='lazy'
              width={24}
              height={24}
              alt={'Hoxton Mix Icon'}
              src={imageResize(
                require('public/images/icons/homepage-hm-symbol-desktop-white.svg'),
              )}
            />
          ) : (
            <img
              loading={'lazy'}
              width={24}
              height={24}
              alt={'Hoxton Mix Icon'}
              src={imageResize(
                require('public/images/icons/homepage-hm-symbol-desktop.svg'),
              )}
            />
          )}

          <p className='text-small mb-0 ps-2'>
            {Strings.formatString(
              `${Strings.copyright}`,
              new Date().getFullYear(),
            )}
          </p>
        </div>
      </li>

      <li className='ps-0 ps-lg-3 d-md-flex align-items-center d-block copyright-numbers'>
        <ul className='w-100 list-unstyled d-flex justify-content-center text-small'>
          <li className='list-border-right'>
            {Strings.companyNo} {company_number}
          </li>
          <li className='list-border-right'>
            {Strings.mlrNumber} {mlr_number}
          </li>
          <li>
            {Strings.icoNumber} {ico_number}
          </li>
        </ul>
      </li>
    </ul>
  )
}

export default Copyright
