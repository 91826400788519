import { TAddon, TDuration } from 'common/cms-types'
import { GA4AddToCart } from '../../types/global'

export const addonToEvent = (data: TAddon, duration: TDuration) => {
  return {
    currency: 'GBP',
    items: [
      {
        item_id: data.slug,
        item_name: data.title,
        price:
          (duration === 'month'
            ? data.pricing.month_price
            : data.pricing.year_price) / 100,
      },
    ],
    value:
      (duration === 'month'
        ? data.pricing.month_price
        : data.pricing.year_price) / 100,
  } as GA4AddToCart
}
