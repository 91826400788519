import cn from 'classnames'
import { ButtonHTMLAttributes, FC, useEffect, useState } from 'react'
import Icon, { IconName } from 'components/Icon'

export const themeClassNames = {
  black: 'btn-black',
  cerise: 'btn-cerise',
  danger: 'btn btn-danger',
  dark: 'btn-dark',
  light: 'btn-light',
  marketplace: 'marketplace-btn',
  outlinePrimary: 'btn btn-outline-primary',
  outlineWhite: 'btn btn-outline-white',
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  tertiary: 'btn-tertiary',
  text: 'btn-link',
  'text-underline': 'btn-link text-underline',
}

export const sizeClassNames = {
  default: '',
  extraLarge: 'btn-extra-lg',
  large: 'btn-lg',
  medium: 'btn-md',
  small: 'btn-sm',
  xs: 'btn-xs',
}

export type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  iconRight?: IconName
  iconLeft?: IconName
  iconLeftColour?: string
  iconRightColour?: string
  theme?: keyof typeof themeClassNames
  size?: keyof typeof sizeClassNames
  disabled?: boolean
  inactive?: boolean
  isSquare?: boolean
  iconHeight?: number
  iconWidth?: number
}

export const Button: FC<ButtonType> = ({
  children,
  className,
  iconHeight,
  iconLeft,
  iconLeftColour,
  iconRight,
  iconRightColour,
  iconWidth,
  inactive,
  isSquare,
  onMouseUp,
  size = 'default',
  theme = 'primary',
  ...rest
}) => {
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    // This fixes an SSR issue where sometimes buttons get stuck as disabled
    setDisabled(!!rest.disabled)
  }, [rest.disabled])
  return (
    <button
      type='button'
      {...rest}
      disabled={disabled}
      onMouseUp={onMouseUp}
      className={cn(
        { btn: true, 'btn--inactive': inactive, 'btn--square': isSquare },
        className,
        themeClassNames[theme],
        sizeClassNames[size],
      )}
    >
      {!!iconLeft && (
        <Icon
          className='me-2'
          name={iconLeft}
          fill={iconLeftColour}
          height={iconHeight}
          width={iconWidth}
        />
      )}
      {children}
      {!!iconRight && (
        <Icon
          className='ms-2'
          name={iconRight}
          fill={iconRightColour}
          height={iconHeight}
          width={iconWidth}
        />
      )}
    </button>
  )
}

Button.displayName = 'Button'
export default Button
