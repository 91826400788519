import { useState } from 'react'
import { useRouter } from 'next/router'

export default function isWithoutNav() {
  if (typeof document === 'undefined') {
    return false
  }
  return document.location.href.includes('withoutNav')
}

export function useWithoutNav() {
  const [preview, setPreview] = useState<boolean>(false)
  const router = useRouter()
  if (router.asPath.includes('withoutNav=')) {
    setPreview(true)
  }
  return preview
}
