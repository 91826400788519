import { useRouter } from 'next/router'
import useLocalStorageState from 'use-local-storage-state'

export default function () {
  const [preview, setPreview] = useLocalStorageState<boolean>('preview', {
    defaultValue: false,
    storageSync: false,
  })
  const router = useRouter()
  if (router.asPath.includes('preview=')) {
    setPreview(true)
  }
  return { preview, setPreview }
}
