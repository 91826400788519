import { ReactNode } from 'react'
import cn from 'classnames'
import { ReactFC } from 'common/types/ReactFC'

interface Heading {
  className?: string
  fontWeight?: 'bold' | 'regular' | 'light'
  font?: 'default' | 'display'
  size?: 1 | 2 | 3 | 4 | 5 | 6
}

const Heading: ReactFC<Heading> = ({
  children,
  className,
  font = 'default',
  fontWeight = 'regular',
  size = 1,
}) => {
  const CustomTag = `h${size}` as keyof JSX.IntrinsicElements
  // @ts-ignore
  const parts = children?.split?.('\n') || [children]
  return (
    <>
      {parts.map((v: ReactNode, i: number) => (
        <CustomTag key={i} className={cn(className, fontWeight, font)}>
          {v}
        </CustomTag>
      ))}
    </>
  )
}

Heading.displayName = 'Heading'
export default Heading
