import { FC } from 'react'

type HotIconType = {}

const HotIcon: FC<HotIconType> = ({}) => {
  return (
    <svg
      className='ms-1'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M17.7576 7.5791C17.3697 7.00941 16.9726 5.96823 16.7576 6.07899C16.4276 6.24899 16.4375 7.7291 16.5475 8.0791C16.6375 8.3491 16.7475 8.95918 16.5475 9.55918C16.4275 9.92918 16.2175 10.2092 15.9975 10.4192C15.7775 9.01918 15.2675 7.28918 14.1075 5.59918C13.2975 4.41918 12.2775 3.39918 11.0875 2.57918C10.8375 2.39918 10.0076 1.89899 9.75757 2.07899C9.49757 2.24899 9.86746 3.05918 9.93746 3.35918C10.1875 4.48918 10.1875 5.67918 9.93746 6.80918C9.77746 7.53918 9.52746 8.17918 9.23746 8.72918C9.05746 8.27918 8.78746 7.80918 8.36746 7.38918C7.54746 6.56918 6.05746 6.22918 6.05746 6.22918C6.05746 6.22918 4.92757 5.89894 4.75757 6.07894C4.58757 6.25894 5.03964 6.35804 5.21746 7.13918C5.49746 8.36918 5.49746 9.51918 5.21746 10.5492C5.10746 10.9692 4.96746 11.2892 4.82746 11.6192C4.61746 12.1092 4.40746 12.6192 4.25746 13.4392C4.20746 13.7292 3.95746 15.2292 4.25746 16.5692C4.83746 19.1192 7.53746 21.3992 10.5375 21.8592C10.5575 21.8592 10.5875 21.8592 10.6075 21.8592C10.9975 21.9392 11.3475 21.9592 11.6175 21.9592C11.8275 21.9592 11.9975 21.9492 12.0775 21.9392C12.1575 21.9392 12.2375 21.9092 12.3175 21.8992C15.2675 21.6492 17.6975 19.7292 18.8475 17.7192C19.8475 15.9692 19.8775 14.2392 19.8875 13.5892C19.9008 12.7212 19.1769 9.66355 17.7576 7.5791Z'
        fill='#FF4F7B'
      />
      <path
        d='M9.88452 17.0791H8.97925V15.2305H7.28296V17.0791H6.37476V12.7959H7.28296V14.4746H8.97925V12.7959H9.88452V17.0791Z'
        fill='white'
      />
      <path
        d='M14.3562 14.9316C14.3562 15.6406 14.1804 16.1855 13.8289 16.5664C13.4773 16.9473 12.9734 17.1377 12.3171 17.1377C11.6609 17.1377 11.157 16.9473 10.8054 16.5664C10.4539 16.1855 10.2781 15.6387 10.2781 14.9258C10.2781 14.2129 10.4539 13.6689 10.8054 13.2939C11.1589 12.917 11.6648 12.7285 12.323 12.7285C12.9812 12.7285 13.4841 12.918 13.8318 13.2969C14.1814 13.6758 14.3562 14.2207 14.3562 14.9316ZM11.2302 14.9316C11.2302 15.4102 11.321 15.7705 11.5027 16.0127C11.6843 16.2549 11.9558 16.376 12.3171 16.376C13.0417 16.376 13.4041 15.8945 13.4041 14.9316C13.4041 13.9668 13.0437 13.4844 12.323 13.4844C11.9617 13.4844 11.6892 13.6064 11.5056 13.8506C11.322 14.0928 11.2302 14.4531 11.2302 14.9316Z'
        fill='white'
      />
      <path
        d='M16.3962 17.0791H15.488V13.5518H14.325V12.7959H17.5593V13.5518H16.3962V17.0791Z'
        fill='white'
      />
    </svg>
  )
}

export default HotIcon
