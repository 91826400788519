import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  aGreatBigThankYou: 'A great big thank you {0}!',
  aGreatBigThankYouDescription: `<p class="mb-2 px-lg-0 px-6">Welcome to The Hoxton Mix! We’ll take it from here.</p><p class="mb-0">We’ve sent your account information to <strong>{0}</strong></p>`,
  anEmailIsOnItsWay: 'An email is on it’s way!',
  annual: 'Annual',
  annualPlanOffer: 'Get {0} months for FREE with the 12-month plan',
  annualSaver: 'Annual Saver',
  authorisation:
    'I authorise The Hoxton Mix Ltd to save this payment method and automatically charge th',
  billingAddress: 'Billing address',
  billingAddressDescription:
    'Select the address that matches your card or payment method.',
  billingType: 'Billing Type',
  cantFindYourCompany: "Can't find your company?",
  cardConfirmation: '{0} ending {1}',
  cardDetail: 'Card Detail',
  cardHolder: 'Card Holder',
  cart: 'Cart',
  change: 'Change',
  city: 'City',
  company: 'Company',
  companyAndContactInformation: 'Company & Contact information',
  companyDetails: 'Company details',
  contact: 'Contact',
  contactDetails: 'Contact details',
  continueToPayment: 'Continue to payment',
  couponError: 'Invalid coupon',
  creditCard: 'Credit Card',
  directDebit: 'Direct Debit',
  discount: 'discount',
  emptyBasket: 'Your bag is empty.',
  enterAddressOrPostcode: 'Postcode',
  enterCoupon: 'Enter coupon code',
  enterYourDetailsManually: 'Enter your details manually',
  enterYourPaymentDetails: 'Enter your payment details',
  error:
    'There was a problem validating your payment method, please try again.',
  goCardlessInstructions:
    'After clicking “Pay with Direct Debit”, you will be redirected to GOCARDLESS to complete your purchase',
  goCheckYourEmail: 'Go check your email',
  hideOrderSummary: 'Hide order summary',
  iAuthorisePayment:
    'I authorise The Hoxton Mix Ltd to save this payment method and automatically charge this Payment method whenever a subscription is associated with it.',
  ifYoureNotAutomaticallyRedirected:
    'Thank you for your order. Please wait while we process your payment.',
  information: 'Information',
  modifySubscription:
    '<p class="mb-lg-5 mb-3">We sent an email to {0}. <br/>It has a magic link that will log you in.</p> <p class="mb-0">The link expires in 24 hours, so please be sure <br class="d-sm-block d-none"/>to use it soon. Please don’t share it with anyone.</p>',
  monthly: 'Monthly',
  essentialBusinessServices: 'Essential Business Services',
  monthsForThePriceOf: '12 Months for the price of {0} Months',
  essentialBusinessServicesDescription: `We work with our partners to provide great deals on essential services for your business.  Take a look at the latest deals that can supercharge your business.`,
  monthsFree: '<span>Months</span> FREE',
  mostPopular:
    '<span class="d-block mb-md-n1 me-md-2 me-1">Most</span><span class="d-block">Popular</span>',
  ohNoYourBagIsEmpty: 'Oh no, your bag is empty!',
  order: 'Order',
  orderDetails: 'Order Details',
  orderSummary: 'Order Summary',
  payAnnually: 'Pay Annually',
  payAnnuallyDescription: 'Pay annually & get {0} months FREE',
  payMonthly: 'Pay Monthly',
  payNow: 'Pay Now',
  payWith: 'Pay with',
  payment: 'Payment',
  paymentMethod: 'Payment method',
  paypal: 'PayPal',

  paypalInstructions:
    'After clicking “PayPal Check out”, you will be redirected to PayPal to complete your purchase',
  apply: 'Apply',
  peopleWhoBought: 'People with this plan also bought',
  postTo: 'Post to',
  postcode: 'Postcode',
  sameAsShippingAddress: 'Same as shipping address',
  searchAddress: 'Search address',
  searchCompaniesHouse: 'Search Companies House for your company',
  searchCompaniesHouseCharity: 'Search charity register for your company',
  searchForCompanyDetailsAgain: 'Search for company details again',
  selectedServices: 'Selected Services',
  shippingAddress: 'Shipping address',
  showOrderSummary: 'Show order summary',
  tellUsAboutYourCompany: 'Tell us about your company',
  tellUsAboutYourCompanyDescription:
    "To provide you with a virtual office, we need to verify your details. It's necessary to do this to comply with Anti-money laundering laws.",
  thanks: 'Thanks',
  thanksSeoTitle: 'Thank you for your order | Hoxton Mix',
  thatsASaving: "That's a saving of {0} a year",
  thisIsSecure: "This is a secure 256-bit SSL Encrypted payment. You're safe",
  thisIsSecure256BitSslEncryptedPayment:
    'This is a secure 256-bit SSL Encrypted payment. You’re safe.',
  youMightAlsoBeInterestedIn: 'You might also be interested in',
  total: 'Total',
  transactionsAreSecure: 'All transactions are secure and encrypted.',
  typeCharityNameHere: 'Type charity name here',
  typeCompanyNameHere: 'Type company name here',
  useADifferentBillingAddress: 'Use a different billing address',
  vatAt: 'VAT @ 20% {0}',
  whereToSendInCaseYouRequirePhysicalCopy:
    'In case you require a physical copy, such as a bank card.',
  whereToSendPostDescription:
    'Please enter the address below of where we should send your post',
  youJustSaved: 'Nice work! You’ve just saved {0}',
  youPurchased: 'You purchased the <strong>{0}</strong>',
  youPurchasedAddons: 'Add-ons <strong>{0}</strong>',
  youWillBeChargedMonth: 'You will be charged <strong>{0}/mo +VAT</strong>',
  youWillBeChargedMonthWithoutVAT:
    'You will be charged <strong>{0}/mo</strong>',
  youWillBeChargedYear: 'You will be charged <strong>{0}/year +VAT</strong>',
  youWillBeChargedYearWithoutVAT:
    'You will be charged <strong>{0}/year</strong>',
  yourOrderIsBeingProcessed: 'Your order is being processed.',
}
export const paymentStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
