import { FC, useEffect, useState } from 'react'
import useShoppingBag from 'common/useShoppingBag' // we need this to make JSX compile

type BagIconType = {}

const BagIcon: FC<BagIconType> = ({}) => {
  const { itemsInBag } = useShoppingBag()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setReady(true)
  }, [])
  return <>{ready ? itemsInBag || '' : null}</>
}

export default BagIcon
