import React, { useRef, useState } from 'react'
import { THeaderLinkGroup } from 'common/cms-types'
import classNames from 'classnames'
import useOnClickOutside from 'project/hooks/useOnClickOutside'
import ActiveLink from './ActiveLink'
import { ReactFC } from 'common/types/ReactFC'
import { useRouter } from 'next/router'
import Link from 'next/link'
import HotIcon from 'components/HotIcon'

type HeaderNavButtonType = {
  headerLinkGroup: THeaderLinkGroup
}

const HeaderNavButton: ReactFC<HeaderNavButtonType> = ({ headerLinkGroup }) => {
  const [active, setActive] = useState<boolean | string>(false)
  const ref = useRef(null)
  const router = useRouter()
  const onClickOutside = () => {
    if (active) {
      setActive(false)
    }
  }
  useOnClickOutside(ref, onClickOutside, 'mouseover')
  const href = headerLinkGroup.href || headerLinkGroup.groups[0].links[0].href

  return (
    <Link className='a-unstyled' href={href}>
      <li
        ref={ref}
        onMouseEnter={() => {
          setActive(`true${Date.now()}`) //not sure why but mouse enter was not always trigerring a re-render
        }}
        onClick={() => {
          router.push(href)
          setActive(false)
        }}
        className='nav-item cursor-pointer nav-item--bordered'
      >
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className='dropdown-hover'
        >
          <ActiveLink className='a-unstyled' href={href}>
            <span
              data-test={`header-title-${headerLinkGroup.href}`}
              className='nav-link-title cursor-pointer'
            >
              {headerLinkGroup.title}
            </span>
          </ActiveLink>

          {!!headerLinkGroup?.groups?.length && (
            <div className={classNames('dropdown-hover-show', { in: active })}>
              <div
                className={classNames('dropdown-hover-content', {
                  'dropdown-hover-content__single-item':
                    headerLinkGroup.groups.length === 1,
                })}
              >
                <div className='row mx-0'>
                  {headerLinkGroup.groups.map((group) => {
                    return (
                      <ul
                        className={classNames('list-unstyled nav-group')}
                        key={group.title}
                      >
                        {group.title && (
                          <li className='dropdown-title text-semibold text-nowrap'>
                            {group.title}
                          </li>
                        )}
                        {group.links.map((link, i) => (
                          <li
                            data-test={`header-link-${link.href}`}
                            onClick={() => {
                              router.push(link.href)
                            }}
                            className='text-nowrap nav-link cursor-pointer ps-0'
                            key={i}
                          >
                            <ActiveLink className='a-unstyled' href={link.href}>
                              <span
                                className='d-flex align-items-center'
                                onClick={(e) => {
                                  setActive(false)
                                }}
                                aria-current='page'
                              >
                                {link.title}
                                {link.hot && <HotIcon />}
                              </span>
                            </ActiveLink>
                          </li>
                        ))}
                      </ul>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </li>
    </Link>
  )
}

export default HeaderNavButton
