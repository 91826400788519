import { FC } from 'react'
import SocialLinks from 'components/SocialLinks'
import { Constants } from 'common/utils'
import Heading from 'components/Heading'
import Strings from 'project/localisation' // we need this to make JSX compile
import cn from 'classnames'

type CompanyInformationType = {
  isLight?: boolean
  className?: string
}

const CompanyInformationCard: FC<CompanyInformationType> = ({
  className,
  isLight,
}) => {
  const data = Constants.companyInformation

  return (
    <>
      <Heading size={5} className={cn('h6', className)}>
        {Strings.companyInformation}
      </Heading>
      <span className='text-small d-block mt-sm-0 mt-4 mb-sm-2 pe-sm-0 pe-8'>
        {Strings.openingHoursDescription}
      </span>
      <span>
        <a
          className='text-link color-blue text-small'
          href={`tel:${data.phone}`}
        >
          {data.phone}
        </a>
      </span>
      <a
        href='mailto:help@hoxtonmix.com'
        className='text-link color-blue text-small d-block d-lg-none d-sm-block text-decoration-none'
      >
        {Constants.companyInformation.email}
      </a>

      <span>
        <SocialLinks linkType='social' isLight={isLight} />
      </span>
    </>
  )
}

export default CompanyInformationCard
