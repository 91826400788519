export const Format = {
  camelCase(val: string | null | undefined): string {
    // hello world > Hello world
    return val ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() : ''
  },

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  },

  capitalizeWords(string: string) {
    return string
      .split(' ')
      .map((v) => Format.capitalizeFirstLetter(v))
      .join(' ')
  },

  enumeration: {
    get(value: string | null | undefined): string {
      // MY_CONSTANT > My constant
      if (!value) {
        return ''
      }
      return Format.camelCase(value.replace(/_/g, ' '))
    },
    set(value: string): string {
      // My Constant > MY_CONSTANT
      return value ? value.replace(/ /g, '_').toUpperCase() : ''
    },
  },
  money: (value: number) => {
    if (value) {
      return (value / 100)?.toLocaleString('en-GB', {
        currency: 'GBP',
        style: 'currency',
      })
    }
    return (0).toLocaleString('en-GB', {
      currency: 'GBP',
      style: 'currency',
    })
  },
}
