import { FC, SVGProps } from 'react'
import classNames from 'classnames' // we need this to make JSX compile

type NavLogoType = SVGProps<SVGSVGElement> & {}

const NavLogo: FC<NavLogoType> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      {...props}
      className={classNames('nav-logo', props.className)}
    >
      <g data-name='Group 549'>
        <path
          data-name='Path 2397'
          d='m16.385 10.642-2.058 2.162-1.381-1.391-1.26-1.269 2.12-2.228a1.6 1.6 0 1 0-2.311-2.2L9.436 7.879l-1.99-2c-2.824 2.776-4.353 5.816-.694 10.073a124.203 124.203 0 0 1-3.216 3.035c.26.23 1.833 1.2 1.836 1.772a112.41 112.41 0 0 0 3.194-3.01v-.006c4.126 3.462 7.124 1.968 9.875-.791l-1.869-1.882 2.12-2.228a1.6 1.6 0 1 0-2.311-2.2Z'
          fill='#fff'
        />
        <g
          id='circle'
          data-name='Ellipse 31'
          fill='none'
          stroke='#fff'
          strokeWidth={2}
        >
          <circle cx={12} cy={12} r={12} stroke='none' />
          <circle cx={12} cy={12} r={11} />
        </g>
      </g>
    </svg>
  )
}

export default NavLogo
